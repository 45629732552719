
import { io } from "socket.io-client";


const socket = io(process.env.REACT_APP_API_URL,{ transports: ['websocket'] });

function sendMessageStatus(name, data) {
    socket.emit(name, data);
}
function handleRegister(name, data) {
    socket.emit(name, data);
}


function notificationFunction() {
    socket.on('notification', (response) => {
        console.log(response,"notification")
        if (response.event === 'markSeen') {
           console.log(response)
        }  else if (response.event === 'error') {
            //  console.log('this is an error', response.data);
        }
    });
}

export {
    sendMessageStatus,
    notificationFunction,
    handleRegister
};

