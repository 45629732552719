import axios from "axios";
import Constants from "../../helpers/constants";
import CommonFunctions from "../../helpers/commonFunctions";
import Api from "../../services/request-service";
export const ERROR = "ERROR";
export const CLEAR_ERROR = "CLEAR_ERROR";
export const ADD_STUDY = "ADD_STUDY";
export const DELETE_STUDY = "DELETE_STUDY";
export const GET_STUDY_BY_ID = "GET_STUDY_BY_ID";
export const UPDATE_STUDY_BY_ID = "UPDATE_STUDY_BY_ID";
export const GET_ALL_STUDIES = "GET_ALL_STUDIES";
export const SET_LOADING = "SET_LOADING";
export const CLEAR_LOADING = "CLEAR_LOADING";
export const ADD_USER_STORE = 'ADD_USER_STORE';
export const GET_USER_STORE = 'GET_USER_STORE';
export const ADD_STORE_CREDENTIALS = 'ADD_STORE_CREDENTIALS';
export const GET_STORE_CREDENTIALS = 'GET_STORE_CREDENTIALS';

export const onError = (error) => {
  return async (dispatch) => {
    dispatch({ type: ERROR, payload: error });
  };
};
export const setLoading = () => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING });
  };
};
export const clearLoading = () => {
  return async (dispatch) => {
    dispatch({ type: CLEAR_LOADING });
  };
};
export const clearError = (error) => {
  return async (dispatch) => {
    dispatch({ type: CLEAR_ERROR, payload: error });
  };
};
export const getStudyById = (studyId, header) => {
  return async (dispatch) => {
    const response = await axios.get(
      Constants.api_urls.get_study_by_id + "?id=" + studyId,
      header
    );
    if (response.data.status) {
      dispatch({ type: GET_STUDY_BY_ID, payload: response.data });
    } else {
      dispatch({ type: ERROR, payload: response.statusText });
    }
  };
};

export const updateStudyById = (study, header) => {
  return async (dispatch) => {
    const response = await axios.put(
      Constants.api_urls.update_Study,
      study,
      header.headers
    );
    if (response.data.status) {
      dispatch({ type: UPDATE_STUDY_BY_ID, payload: response.data });
    } else {
      dispatch({ type: ERROR, payload: response.statusText });
    }
  };
};

export const deleteStudy = (id, header) => {
  var data = {
     id
  };
  return async (dispatch) => {
    const response = await axios.delete(Constants.api_urls.delete_Study,
        {
            headers: header.headers,
            data: data
        }
    );
    if (response.data.status) {
      dispatch({ type: DELETE_STUDY, payload: id });
    } else {
      dispatch({ type: ERROR, payload: response.statusText });
    }
  };
};

export const addStudy = (study, header) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING });
    try {
      const response = await axios.post(
        Constants.api_urls.add_study,
        study,
        header
      );
      if (response.data.status) {
        dispatch({ type: ADD_STUDY, payload: response.data });
        dispatch({ type: CLEAR_LOADING });
      } else {
        dispatch({ type: ERROR, payload: response.statusText });
        dispatch({ type: CLEAR_LOADING });
      }
    } catch (error) {
      dispatch({ type: ERROR, payload: error.response.data.message });
      dispatch({ type: CLEAR_LOADING });
    }
  };
};

export const addUserStore = (store, header) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING });
    try {
      const response = await axios.post(
        Constants.api_urls.add_user_store,
        store,
        header
      );
      if (response.data.status) {
        dispatch({ type: ADD_USER_STORE, payload: response.data });
        dispatch({ type: CLEAR_LOADING });
      } else {
        dispatch({ type: ERROR, payload: response.statusText });
        dispatch({ type: CLEAR_LOADING });
      }
    } catch (error) {
      dispatch({ type: ERROR, payload: error.response.data.message });
      dispatch({ type: CLEAR_LOADING });
    }
  };
};
export const getUserStores = (userId,studyId, header) => {
  return async (dispatch) => {
    const response = await axios.get(
      Constants.api_urls.get_user_store + "userId=" + userId + "&studyId=" + studyId,
      header
    );
    if (response.data.status) {
      dispatch({ type: GET_USER_STORE, payload: response.data });
    } else {
      dispatch({ type: ERROR, payload: response.statusText });
    }
  };
};
export const addStoreCredentials = (storeCredential, header) => {
  return async (dispatch) => {
    const response = await axios.put(
      Constants.api_urls.add_store_credentials,
      storeCredential,
      header.headers
    );
    if (response.data.status) {
      const modifiedData = {
        ...storeCredential,
        data:response.data
      }
      dispatch({ type: ADD_STORE_CREDENTIALS, payload: modifiedData });
    } else {
      dispatch({ type: ERROR, payload: response.statusText });
    }
  };
};

export const getAllStudies = (header) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING });
    try {
      const response = await axios.get(
        Constants.api_urls.get_all_studies,
        header
      );
      dispatch({ type: GET_ALL_STUDIES, payload: response.data });
      dispatch({ type: CLEAR_LOADING });
    } catch (error) {
      dispatch({ type: ERROR, payload: error.response.data.message });
      dispatch({ type: CLEAR_LOADING });
    }
  };
};
