import axios from "axios";
import Constants from "../../helpers/constants";
import CommonFunctions from "../../helpers/commonFunctions";
import * as actionTypes from "../actions";
import Api from "../../services/request-service";
export const ERROR = "ERROR";
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const LOGIN_ADMIN = "LOGIN_ADMIN";
export const LOGIN_VERIFY = "LOGIN_VERIFY";
export const ADD_ADMIN = "ADD_ADMIN";
export const ADD_USER = "ADD_USER";
export const ADD_COORDINATOR = 'ADD_COORDINATOR';
export const ADD_COACH = 'ADD_COACH';
export const DELETE_USER = "DELETE_USER";
export const DELETE_COACH = "DELETE_COACH";
export const DELETE_COORDINATOR = "DELETE_COORDINATOR";
export const DELETE_ADMIN = "DELETE_ADMIN";
export const LOGOUT = "LOGOUT";
export const GET_ROLES = "GET_ROLES";
export const GET_USERS = "GET_USERS";
export const GET_COACH = "GET_COACH";
export const GET_COORDINATOR = "GET_COORDINATOR";
export const GET_ADMIN = "GET_ADMIN";
export const SET_LOADING  = 'SET_LOADING';
export const CLEAR_LOADING = 'CLEAR_LOADING';

export const onError = (error) => {
  return async (dispatch) => {
    dispatch({ type: ERROR, payload: error });
  };
};
export const setLoading = ()=>{
  return async (dispatch)=>{
    dispatch({type:SET_LOADING})
  }
}
export const clearLoading = ()=>{
  return async (dispatch)=>{
    dispatch({type:CLEAR_LOADING})
  }
}
export const clearError = (error) => {
  return async (dispatch) => {
    dispatch({ type: CLEAR_ERROR, payload: error });
  };
};

export const getUser = (header)=> {
  return async (dispatch) => {
    try {
      const response = await axios.get(Constants.api_urls.get_all_users + '?role=' + Constants.roles_list.participant, header);
      if (response.data.status) {
        dispatch({type: GET_USERS, payload: response.data});
      } else {
        dispatch({type: ERROR, payload: response.statusText});
      }
    } catch (error) {
      dispatch({type: ERROR, payload: CommonFunctions.parseApiError(error)});
    }
  }
}

export const getCoach = (header)=> {
  return async (dispatch) => {
    try {
      const response = await axios.get(Constants.api_urls.get_all_users + '?role=' + Constants.roles_list.coach, header);
      if (response.data.status) {
        dispatch({type: GET_COACH, payload: response.data});
      }

    } catch (error) {
      dispatch({type: ERROR, payload: CommonFunctions.parseApiError(error)});
    }
  }
}

export const getCoordinator = (header)=> {
  return async (dispatch) => {
    try {
      const response = await axios.get(Constants.api_urls.get_all_users + '?role=' + Constants.roles_list.coordinator, header);
      if (response.data.status) {
        dispatch({type: GET_COORDINATOR, payload: response.data});
      } else {
        dispatch({type: ERROR, payload: response.statusText});
      }
    } catch (error) {
      dispatch({type: ERROR, payload: CommonFunctions.parseApiError(error)});
    }
  }
}

export const getAdmin = (header)=> {
  return async (dispatch) => {
    try {
      const response = await Api.get(Constants.api_urls.get_participant_cluster_group_coach + Constants.roles_list.admin, header);
      if (response.data.status) {
        dispatch({type: GET_ADMIN, payload: response.data});
      } else {
        dispatch({type: ERROR, payload: response.statusText});
      }
    } catch (error) {
      dispatch({type: ERROR, payload: CommonFunctions.parseApiError(error)});
    }
  }
}

export const deleteUser = (id,header)=> {
  const data = {
    id: id
  }
  return async (dispatch) => {
    try {
      const response = await axios.delete(Constants.api_urls.del_user,
          {
            headers: header.headers,
            data: data
          }
          // header,{data:{id:id}}
      );
      if (response.data.status) {
        dispatch({type: DELETE_USER, payload: id});
      }
    } catch (error) {
      dispatch({type: ERROR, payload: CommonFunctions.parseApiError(error)});
    }
  };
}

export const deleteCoach = (id,header)=> {
  const data = {
    id: id
  }
  return async (dispatch) => {
    try {
      const response = await axios.delete(Constants.api_urls.del_user,
          // header,{data:{id:id}}
          {
            headers: header.headers,
            data: data
          }
      );
      if (response.data.status) {
        dispatch({type: DELETE_COACH, payload: id});
      }
    } catch (error) {
      dispatch({type: ERROR, payload: CommonFunctions.parseApiError(error)});
    }

  };
}

export const deleteCoordinator = (id,header)=> {
  const data = {
    id: id
  }
  return async (dispatch) => {
    try {
      const response = await axios.delete(Constants.api_urls.del_user,
          {
            headers: header.headers,
            data: data
          }
      );
      if (response.data.status) {
        dispatch({type: DELETE_COORDINATOR, payload: id});
      } else {
        dispatch({type: ERROR, payload: response.statusText});
      }
    } catch (error) {
      dispatch({type: ERROR, payload: CommonFunctions.parseApiError(error)});
    }

  };
}

export const AddAdmin = (admin) => {
  return async (dispatch) => {
    dispatch({type: SET_LOADING});
    try {
      const response = await axios.post(Constants.api_urls.add_admin, admin);

      if (response.data.status) {
        dispatch({type: ADD_ADMIN, payload: response.data});
        dispatch({type: CLEAR_LOADING})
      } else {
        dispatch({type: ERROR, payload: response.statusText});
        dispatch({type: CLEAR_LOADING})
      }

    } catch (error) {
      dispatch({type: ERROR, payload: CommonFunctions.parseApiError(error) });
      dispatch({type: CLEAR_LOADING})
    }

  };
};

export const deleteAdmin = (id,header)=> {
  const data = {
    id: id
  }
  return async (dispatch) => {
    try {
      const response = await axios.delete(Constants.api_urls.del_user,
          {
            headers: header.headers,
            data: data
          }
      );
      if (response.data.status) {
        dispatch({type: DELETE_ADMIN, payload: id});
      } else {
        dispatch({type: ERROR, payload: response.statusText});
      }
    } catch (error) {
      dispatch({type: ERROR, payload: CommonFunctions.parseApiError(error)});
    }

  };
}

export const loginAdmin = (admin) => {
  return async (dispatch) => {
    dispatch({type: SET_LOADING});
    try {
      const response = await axios.post(Constants.api_urls.user_sign_in, admin);
      if (response.data.status === true) {
        if (response.data?.data?.user?.role == Constants.roles_list.participant) {
          dispatch({type: ERROR, payload: Constants.static_strings.participantCantLogin});
          return dispatch({type: CLEAR_LOADING});
        }
        if (response.data?.data?.user?.role != Constants.roles_list.coach) {
          dispatch({type: actionTypes.UPDATE_THEME, payload: {defaultPath: '/manage-participant/participants'}});
        }else{
          dispatch({type: actionTypes.UPDATE_THEME, payload: {defaultPath: '/coach-view/coach-calendar'}});
        }
        CommonFunctions.storeUser(JSON.stringify(response.data.data));

        dispatch({type: LOGIN_ADMIN, payload: response.data.data});
        dispatch({type: CLEAR_LOADING})
      } else {
        dispatch({type: ERROR, payload: response.statusText});
        dispatch({type: CLEAR_LOADING})
      }
    } catch (error) {
      dispatch({type: ERROR, payload: CommonFunctions.parseApiError(error)});
      dispatch({type: CLEAR_LOADING})
    }

  };
};
export const verifyLogin = (admin) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADING });
    try {
      const response = await axios.post(Constants.api_urls.user_sign_in_otp, admin);
      if(!response.data.status) {
        dispatch({ type: ERROR, payload: response.data.message })
      }
      
      dispatch({ type: LOGIN_VERIFY, payload: response.data.status })
    } catch (error) {
      dispatch({ type: LOGIN_VERIFY, payload: false })
      dispatch({ type: ERROR, payload: CommonFunctions.parseApiError(error) });
    } finally {
      dispatch({type: CLEAR_LOADING})
    }
  }
}

export const addUser = (user, header) => {
  return async (dispatch) => {
    dispatch({type:SET_LOADING});
    try {

      const response = await axios.post(Constants.api_urls.add_user, user, header.headers);
      if (response.data.status) {
        dispatch({ type: ADD_USER, payload: response.data });
        dispatch({type:CLEAR_LOADING})
      } else {
        dispatch({ type: ERROR, payload: response.statusText });
        dispatch({type:CLEAR_LOADING})
      }
    } catch (error) {
      dispatch({ type: ERROR, payload: CommonFunctions.parseApiError(error) });
      dispatch({type:CLEAR_LOADING})
    }

  };
};

export const addCoach = (user, header) => {
  return async (dispatch) => {
    dispatch({type:SET_LOADING});
    try {

      const response = await axios.post(
        Constants.api_urls.add_user,
        user,
        header.headers
      );
      if (response.data.status) {
        dispatch({ type: ADD_COACH, payload: response.data });
        dispatch({type:CLEAR_LOADING})
      } else {
        dispatch({ type: ERROR, payload: response.statusText });
        dispatch({type:CLEAR_LOADING})
      }
    } catch (error) {
      dispatch({ type: ERROR, payload: CommonFunctions.parseApiError(error) });
      dispatch({type:CLEAR_LOADING})
    }

  };
};

export const addCoordinator = (user, header) => {
  return async (dispatch) => {
    dispatch({type:SET_LOADING});
    try {

      const response = await axios.post(
        Constants.api_urls.add_user,
        user,
        header
      );
      if (response.data.status) {
        dispatch({ type: ADD_COORDINATOR, payload: response.data });
        dispatch({type:CLEAR_LOADING})
      } else {
        dispatch({ type: ERROR, payload: response.statusText });
        dispatch({type:CLEAR_LOADING})
      }
    } catch (error) {
      dispatch({ type: ERROR, payload: CommonFunctions.parseApiError(error) });
      dispatch({type:CLEAR_LOADING})
    }

  };
};
export const getRoles = (header) => {
  return async (dispatch) => {
    dispatch({type:SET_LOADING});
    try {
      const response = await axios.get(Constants.api_urls.get_all_roles, header);
      dispatch({ type: GET_ROLES, payload: response.data });
      dispatch({type:CLEAR_LOADING})
    } catch (error) {
      dispatch({ type: ERROR, payload: CommonFunctions.parseApiError(error) });
      dispatch({type:CLEAR_LOADING})
    }

  };
};


export const logout = ()=>{
  return async (dispatch)=>{
      CommonFunctions.clearStore();
      dispatch({type:LOGOUT})
  }
}
