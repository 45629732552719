import constants from './constants';

const commonFunctions = {
    storeUser: (data) => {
        localStorage.setItem(constants.local_storage_keys.userData, data);
    },
    getUser: () => {
        let userData = localStorage.getItem(constants.local_storage_keys.userData);
        if(userData)
            return JSON.parse(userData);
        return null;
    },
    getUserToken: () => {
        let userData = localStorage.getItem(constants.local_storage_keys.userData);
        if(userData){
            userData = JSON.parse(userData);
            return userData.token;
        }
    },
    removeUser: () => {
        localStorage.removeItem(constants.local_storage_keys.userData);
    },
    clearStore:()=>{
        localStorage.removeItem(constants.local_storage_keys.store);
    },

    parseApiError: (error) => {
        let err = error?.response?.data?.message || error?.response?.message || error?.response || error;
        if(err?.status == 404){
            return constants.static_strings.notFound;
        }
        return err;
    }
};
export default commonFunctions;
